import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../components/Layout';
import { DataPageSidebarLinks } from '../../helpers/constants';
import { Link } from 'gatsby';
import InfoCard from '../../components/InfoCard';
import { ExternalResourcesData } from '../../helpers/external-resources';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout pageTitle="External Resources" sidebarLinks={DataPageSidebarLinks} activeHeaderLink="Data" mdxType="Layout">
      <h1>{`External Resources`}</h1>
      <p>{`EEW's work draws on open datasets provided by the U.S. federal government— but
our work only scratches the surface! Below we list a collection of open federal
data tools and resources relevant to various aspects of environmental governance,
including health and toxics; air and surface quality water, and much more, for
you to delve deeper. (Descriptions below are typically drawn from the sites themselves.)`}</p>
      <p>{`Is there a dataset that you would especially like to see us incorporate into our
work? Please `}<a parentName="p" {...{
          "href": "/about/contact"
        }}>{`let us know!`}</a></p>
      <div className='row'>
  {ExternalResourcesData.resources.map(resource => <InfoCard title={resource.Name} description={resource.Description} link={resource.Link} mdxType="InfoCard" />)}
      </div>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      